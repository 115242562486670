import { useProgram } from 'contexts/program';
import { useAudiencesQuery } from 'hooks/audience';
import { useInitiativesQuery } from 'hooks/initiatives';
import { useTopicsQuery } from 'hooks/topics';
import { Option } from 'models/insight/json/filterJson';
import React from 'react';
import { FilterDropdown } from 'shared/FilterDropdown';
import { useContentQuery } from '../../../../../hooks/content';
import { generateMonthlyValues } from '../components/DashboardParameter';

type DashboardFilterProps = {
  onChange: (filter: string, values: string[]) => void;
  onClose?: (dashboardName: string) => void;
};

const DashboardFilterWrapper: React.FC<
  DashboardFilterProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    queryHook: any;
    filterKey: string;
    label: string;
    valueTransform?: (data: {
      [key: string]: string;
    }) => {
      label: string;
      value: string;
    };
    multiSelect?: boolean;
  }
> = ({
  onChange,
  queryHook,
  filterKey,
  label,
  valueTransform = (data) => ({
    label: data?.name,
    value: data?.name,
  }),
  multiSelect = true,
}) => {
  const programId = useProgram().id;
  const { data, isLoading } = queryHook({ programId });
  const [selectedValues, setSelectedValues] = React.useState<string[]>([]);

  const onFilterChange = React.useCallback(
    (values: string[]) => {
      if (multiSelect) {
        setSelectedValues(values);
      } else {
        setSelectedValues([values[values.length - 1]]);
      }
      onChange(filterKey, values);
    },
    [onChange, filterKey, multiSelect]
  );

  if (isLoading) return <></>;
  if (data?.length) {
    const options = data.map(valueTransform);
    return (
      <FilterDropdown
        onChange={onFilterChange}
        options={options}
        label={label}
        multiSelect={multiSelect}
        selectedOptions={options.filter((opt: Option) => {
          if (multiSelect) {
            return selectedValues?.includes(opt.value);
          }
          return selectedValues[0] === opt.value;
        })}
      />
    );
  }
  return <div>No {label.toLowerCase()} found</div>;
};

export const DashboardFilter: React.FC<{
  filter: string;
  onChange: (filter: string, values: string[]) => void;
  onClose?: (dashboardName: string) => void;
}> = ({ filter, onChange }) => {
  const dashboardFilters: Record<
    string,
    {
      queryHook: unknown;
      filterKey: string;
      label: string;
      valueTransform?: (data: {
        [key: string]: string;
      }) => {
        label: string;
        value: string;
      };
      multiSelect?: boolean;
    }
  > = {
    content: {
      queryHook: useContentQuery,
      filterKey: 'content_id',
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: `${data.id} ${data.title}`,
          value: data.id.toString(),
        };
      },
      label: 'Content',
    },
    topics: {
      queryHook: useTopicsQuery,
      filterKey: 'topic_id',
      label: 'Topics',
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.name,
          value: data.id.toString(),
        };
      },
    },
    initiatives: {
      queryHook: useInitiativesQuery,
      filterKey: 'initiative_tag_id',
      label: 'Initiative',
      multiSelect: false,
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.name,
          value: data.id.toString(),
        };
      },
    },
    audiences: {
      queryHook: useAudiencesQuery,
      filterKey: 'audience_id',
      label: 'Audiences',
      valueTransform: (data: { [key: string]: string }) => {
        return {
          label: data.title,
          value: data.id.toString(),
        };
      },
    },
    timeRange: {
      queryHook: () => {
        return {
          data: generateMonthlyValues(),
          isLoading: false,
        };
      },
      filterKey: 'month_year_filterable',
      label: 'Time Range',
    },
  };

  return (
    dashboardFilters[filter] && (
      <DashboardFilterWrapper
        onChange={onChange}
        {...dashboardFilters[filter]}
      />
    )
  );
};

type DashboardFilterObj = {
  filters: string[];
  parameters: string[];
};

const DefaultDashboardFilters: DashboardFilterObj = {
  filters: [],
  parameters: [],
};

// temporary hardcoding of filters for executive dashboard
const EXECUTIVE_DASHBOARD_FILTERS: Record<string, DashboardFilterObj> = {
  // campaigns
  '2a5cb0eb-2d3b-4e6f-a1f3-efa58040ba7b': {
    filters: ['audiences', 'topics'],
    parameters: ['dateRange'],
  },
  // initiavites dashboard
  '283a479a-5b0c-445e-aa74-45ba44238f37': {
    filters: ['audiences', 'initiatives'], // todo: add user department
    parameters: ['dateRange'],
  },
  // community dashboard
  'b73e991a-91c6-4dce-8844-bd52bba3de25': {
    filters: ['timeRange'],
    parameters: [],
  },
  // christian's custom dashboard:
  '14c332a5-37e1-4871-903d-80e29ff065e5': {
    filters: ['content'],
    parameters: ['dateRange'],
  },
};

export const useDashboardFilters = (
  dashboardId: string
): DashboardFilterObj => {
  return EXECUTIVE_DASHBOARD_FILTERS[dashboardId] ?? DefaultDashboardFilters;
};
