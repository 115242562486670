import { JourneyGraph } from 'models/journeys/journey';

import deepEqual from 'fast-deep-equal';
import React, { useRef, useMemo } from 'react';

function useDeepCompareMemo<TThing>(
  factory: () => TThing,
  dependencies: React.DependencyList
): TThing {
  const dependenciesRef = useRef<React.DependencyList>(dependencies);
  const signalRef = useRef<number>(0);

  if (!deepEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
    signalRef.current += 1;
  }

  // We don't want to supply the factory to underlying useMemo
  // as it forces clients to keep the factory function's identity stable
  // which would be different to how React's useMemo works
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(factory, [signalRef.current]);
}

export type JourneyGraphTopologyNode = Pick<
  JourneyGraph['steps'][0],
  'id' | 'type' | 'next'
>;
export interface JourneyGraphTopology {
  steps: readonly JourneyGraphTopologyNode[];
  isLive: boolean;
}

export function useJourneyGraphTopology(
  journeyGraph: JourneyGraph
): JourneyGraphTopology {
  const journeyTopology: JourneyGraphTopology = {
    isLive: journeyGraph.isLive,
    steps: journeyGraph.steps.map((step) => ({
      id: step.id,
      type: step.type,
      next: step.next,
    })),
  };
  return useDeepCompareMemo(() => journeyTopology, [journeyTopology]);
}
