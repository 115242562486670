import { RefObject, useCallback } from 'react';
import { useDocumentEvent } from './useDocumentEvent';

export function useOnClickOutside(
  ref: RefObject<HTMLElement>,
  handler: (event: MouseEvent) => void
): void {
  const maybeFire = useCallback(
    (event: MouseEvent) => {
      if (
        event.target instanceof Element &&
        ref.current &&
        !ref.current.contains(event.target)
      )
        handler(event);
    },
    [ref, handler]
  );
  useDocumentEvent('click', maybeFire);
}
